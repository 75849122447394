import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from 'src/environments/environment';
import {CommonOptions} from '../../../interfaces/interfaces';
import {CommonService} from '../../../services/modules/common.service';
import {HttpErrorResponse} from '@angular/common/http';
import {FormControl} from '@angular/forms';
import {FechasService} from 'src/app/core/services/utils/fechas.service';
import {CommonMetodService} from 'src/app/core/services/utils/commonMetod.service';
import {EmployerService} from 'src/app/core/services/modules/employer.service';
import {CompaniesService} from 'src/app/core/services/modules/companies.service';
import {TerminationService} from 'src/app/core/services/modules/termination-service';
import {Router} from '@angular/router';
import {DialogService} from 'src/app/core/services/utils/dialog.service';
import {SnackBarService} from "../../../services/utils/snackBar.service";

export interface DialogData {
  CompanyInfoId: any;
  IsRequest: boolean;
}

@Component({
  selector: 'app-dialog-create-termination',
  templateUrl: './dialog-create-termination.component.html',
  styleUrls: ['./dialog-create-termination.component.scss']
})
export class DialogCreateTerminationComponent implements OnInit {
  s3Bucket: string;

  controlEmployer = new FormControl();
  controlContractSelected = new FormControl();
  controlTypeTermination = new FormControl();

  flagExitContractContracts = true;

  employers: any[] = [];
  personals: any[] = [];
  personSelected: any [] = [];
  typesTermination: any[] = [];
  employerSelected: any [] = [];
  personalSelected: any [] = [];

  states: CommonOptions[] = [];
  lastStages: CommonOptions[] = [];
  companyDocumentType: CommonOptions[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogCreateTerminationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fechasService: FechasService,
    public commonMetodService: CommonMetodService,
    private employerService: EmployerService,
    private companiesService: CompaniesService,
    private commonService: CommonService,
    private terminationService: TerminationService,
    private router: Router,
    private dialogService: DialogService,
    private snackBService: SnackBarService,
  ) {
  }

  ngOnInit(): void {
    this.s3Bucket = environment.api.s3Bucket;
    this.employerService.getEmployers().subscribe((employers: any) => {
      this.employers = employers.filter(emplo => emplo.IsActive === true && this.data.CompanyInfoId === emplo.CompanyInfoParentId);
      if (this.employers.length === 1) {
        this.employerSelected.push(this.employers[0]);
        this.valueSelectedProyect(this.employerSelected[0].Id);
      }
    });
    this.companiesService.getPersonalTerminationRequestTerminationType(this.data.CompanyInfoId).subscribe(types => {
      this.typesTermination = types.filter(x => x.IsActive === true);
    });
    this.getServices();
  }

  setValue() {
    if (this.controlEmployer.value !== undefined) {
      this.controlEmployer.setValue('');
      this.controlContractSelected.setValue('');
      this.employerSelected = [];
      this.personalSelected = [];
      this.personSelected = [];
      this.flagExitContractContracts = true;
    }
  }

  setValueContract() {
    if (this.controlContractSelected.value !== undefined) {
      this.controlContractSelected.setValue('');
      this.personSelected = [];
      this.flagExitContractContracts = true;
    }
  }

  valueSelectedProyect(e) {
    this.employerSelected = [];
    this.employers.forEach((proyect: any) => {
      proyect.Id === e ? this.employerSelected.push(proyect) : '';
    });
    if (this.employerSelected.length > 0) {
      this.employerService.getPersonalEmployerEmployerInfoId(this.employerSelected[0].Id).subscribe((rest: any) => {
        this.personals = rest;
      });
    }
  }

  getServices() {
    this.commonService.getCommonOptions('PersonalContractStage').subscribe((data: CommonOptions[]) => {
      this.lastStages = data;
    });
  }

  onSelectionChangeContract(e) {
    this.personSelected = [];
    this.personals.forEach((contract: any) => {
      contract.Id === e ? this.personSelected.push(contract) : '';
    });
  }

  getDisabled(): boolean {
    let enabled = false;
    this.personSelected.length === 1 && this.employerSelected.length === 1 &&
    this.controlTypeTermination.value !== null ? enabled = true : enabled = false;
    return enabled;
  }

  iniciar() {
    this.dialogService.openDialogConfirm('Al iniciar la solicitud de retiro se inhabilitara en todos los contratos.', true, true,
      'CANCELAR', 'CONFIRMAR', 'Fin de acceso ' + this.fechasService.getDatetoday()).afterClosed().subscribe(rest => {
      rest !== false ? this.validationRemoveAcceso(true) : this.validationRemoveAcceso(false);
    });
  }

  validationRemoveAcceso(removeAcceso) {
    const PersonalEmployerId = this.personSelected[0].Id;
    const model = {
      PersonalEmployerId,
      TerminationStateId: this.getLastStageId(),
      TerminationTypeId: this.controlTypeTermination.value,
      TerminationDate: this.fechasService.getDateFormatDatePikerDATEHORANOW(),
      removeAccess: true
    };
    this.terminationService.postPersonalTerminationRequest(model, removeAcceso).subscribe((restPost: any) => {
      this.requestId(restPost.PersonalCompanyInfoId, restPost.Id, PersonalEmployerId, restPost.PersonalEmployerSnapshotId);
      this.dialogRef.close(true);
    }, (error: HttpErrorResponse) => {
      if (error.status === 418) {
        console.log('error', error.error);
        // Manejar el redireccionamiento si existe la solicitud de retiro.
        this.requestId(error.error.PersonalCompanyInfoId, error.error.PersonalTerminationRequestId,
          PersonalEmployerId, error.error.PersonalEmployerSnapshotId);
        this.dialogRef.close(true);
      } else if (error.status === 420) {
        this.snackBService.openSnackBar('DILIGENCIAR EL CAMPO DE SALARIO Y AUXILIO DE TRANSPORTE', 'X', 4000);
      }else if (error.status === 422) {
        this.snackBService.openSnackBar('LA PERSONA REQUIERE REVISIÓN PARA HACER EL RETIRO', 'X', 4000);
       }
    });
  }

  requestId(PersonalCompanyInfoId, IdRequest, PersonalEmployerId, personalEmployerSnapshotId) {
    console.log(PersonalCompanyInfoId, IdRequest, PersonalEmployerId, personalEmployerSnapshotId);
    if (personalEmployerSnapshotId !== null) {
      this.router.navigate(['/admin/termination/termination', PersonalCompanyInfoId, IdRequest, personalEmployerSnapshotId, true]);
    }else{
      this.router.navigate(['/admin/termination/termination', PersonalCompanyInfoId, IdRequest, PersonalEmployerId, false]);
    }
  }

  getLastStageId(): number {
    let idStage = 0;
    this.lastStages.forEach((stage: CommonOptions) => {
      if (stage.Value === 'INI') {
        idStage = stage.Id;
      }
    });
    return idStage;
  }

  getImg(logo) {
    const img = (logo === null || logo === '' || logo === undefined ? 'upload/images/form-images/file_235c080f-43b7-42c6-bd43-0af779cdb223.svg' : logo);
    return img;
  }

}
